import React,{ Component, useEffect } from "react";
import {Link, Navigate } from 'react-router-dom';
import axios from "axios";
import { AppImages, constant } from "../../utils/constant";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from './../layouts/loader'
class Login extends Component{
    
    constructor(props) {
        
        super(props);
        this.state = {
          email: "",
          password: "",
          msg: "",
          isLoading: false,
          redirect: false,
          errMsgEmail: "",
          errMsgPwd: "",
          errMsg: "",
        };
        
        this.onChangehandler =this.onChangehandler.bind(this);
      }


            //on chnage event 
            onChangehandler = (e) => {
                let name = e.target.name;
                let value = e.target.value;
                let data = {};
                data[name] = value;
                this.setState(data);
                
                
              };
            
        
                //LOGIN API CALL
            onSignInHandler = (e) => {

                e.preventDefault();
                this.setState({
                    errMsgEmail: "",
                    errMsgPwd: "",
                    errMsg: "",
                  }); 

                if(this.state.email==""){
                    this.setState({
                        errMsgEmail: "This field is required",
                      });                    
                }else

                
                if(this.state.password==""){
                    this.setState({
                        errMsgPwd: "This field is required",
                      });                    
                }else {

                this.setState({ isLoading: true });
                
                axios
                  .post(constant.LOGIN, {
                    email: this.state.email,
                    password: this.state.password,
                  })
                  .then((response) => {
                    this.setState({ isLoading: false });
                    if (response.data.status == true) {
                        localStorage.setItem("isLoggedIn", true);
                        localStorage.setItem("userData", JSON.stringify(response.data));
                        localStorage.setItem("user_token", "Bearer " + response.data.token);

                       var header_data={
                            name: response.data.data.name,
                            matched: response.data.data.matched,
                            fulfilled: response.data.data.fulfilled,
                            wallet: response.data.data.wallet,
                        };
                  
                        localStorage.setItem("header_data",JSON.stringify(header_data));
                        localStorage.setItem("login_verify","false");
                        if(response.data.data.app_mode =="production"){                            
                            this.setState({                       
                                msg: response.data.message,
                                redirect: true,
                            });      
                        }else{
                            this.setState({                       
                                msg: response.data.message,                                
                            });      
                        }

                        toast.success(response.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                    if (response.data.status == false && response.data.success == undefined ) {
                      if(this.state.email == ""){
                        this.setState({
                            errMsgEmail: response.message
                        })
                      }else if(this.state.password == "") {
                        this.setState({
                            errMsgPwd: response.data.message,
                         });
                      } else {
                        this.setState({
                            errMsg: response.data.message,
                         });
                      }
                      

                        // toast.success(response.data.message, {
                        //     position: "top-right",
                        //     autoClose: 5000,
                        //     hideProgressBar: false,
                        //     closeOnClick: true,
                        //     pauseOnHover: true,
                        //     draggable: true,
                        //     progress: undefined,
                        // });  

                        // setTimeout(() => {
                        //     this.setState({ errMsgEmail: "", errMsgPwd: "" });
                        // }, 2000);
                    } else if (response.data.status == false && response.data.success == false) {
                        console.log('3');
                      this.setState({
                        errMsg: response.data.message,
                      });
                      setTimeout(() => {
                        this.setState({ errMsg: "" });
                      }, 2000);

                      toast.success(response.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    }
                  })
                  .catch((error) => {
                    console.log('4');
                        console.log(error);
                  }); }
              };

    render() {

        document.title = "Dealerhop | Login";
        const userdata1 = localStorage.getItem("userData");
        const userdata =JSON.parse(userdata1);    
    
        let step =0;
        let is_skip_payment =0;
       
        // console.log(userdata)
        if(userdata){
            step = (userdata.data.step);    
            is_skip_payment = (userdata.data.is_skip_payment);    
        }

        if (this.state.redirect) {
            return <Navigate  to="/verify" />;
        }

        
      
        if(step == 2 || step == 1){
            
            return <Navigate  to="/step/contact_info" />;
        }else if(step == 3 && is_skip_payment == 0){
            return <Navigate  to="/available_customers" />;            
        }else if(step == 3 && is_skip_payment == 1){
            return <Navigate  to="/available_customers" />;
        }else if(step == 4){
            return <Navigate  to="/available_customers" />;
        }else{}


        
        const login = localStorage.getItem("isLoggedIn");
      
        if (login) {
          return <Navigate to="/available_customers" />;
        }
      
        const isLoading = this.state.isLoading;

        return (
            <>
                <div className="vertical_logobox">
                    <img src={ AppImages.logo_white }  />
                </div>
                <div className="rught_sidebox">
                    <div className="create_detailsbox">
                        <div className="create_innerboxdiv">
                            <h2>Login</h2>
                            <div className="account_inputbox">
                                <p>Email Address* </p>
                                <input
                                    type="text"
                                    name="email"
                                    placeholder="Enter Email Address"
                                    onChange={this.onChangehandler}
                                />

                                <span className="text-danger">
                                    {this.state.msg}
                                </span>
                                {!this.state.email ? (
                                    <span className="text-danger">
                                        {this.state.errMsgEmail}
                                    </span>
                                ) : null}
                            </div>
                            <div className="account_inputbox">
                                <p>Password* </p>
                                <input
                                    type="password"
                                    name="password"
                                    placeholder="Enter Password"
                                    onChange={this.onChangehandler}
                                />

                                {!this.state.password ? (
                                    <span className="text-danger">
                                        {this.state.errMsgPwd}
                                    </span>
                                ) : null}

                                <span className="text-danger">
                                    {this.state.errMsg}
                                </span>
                            </div>
                            <p className="resetpassword_div">
                                Forgot Password? 
                                <Link to="/forgot_password"> Reset Password</Link>
                            </p>

                            <div className="createaccount_btn">
                                <Link to="" onClick={this.onSignInHandler}>
                                    Login Now
                                    {isLoading ? (
                                        <Loader />
                                    ) : (
                                       null
                                    )}
                                </Link>
                            </div>
                        </div>
                        <div className="alreadyaccount_boxset">
                            <p>
                                Already have an account?
                                <Link to="/register"> Register Here</Link>
                            </p>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </>
        );
    }
}

export default Login;