import { Navigate } from "react-router-dom";

export class constant {
    static ApplicationMode = {
        local: `local`,
        staging: `staging`,
        live: `live`,
    };

    static mode = this.ApplicationMode.live;

    static base_url =
        this.mode === `local`
            ? `http://localhost/dealerhop/public/api/v1/`
            : this.mode === `staging`
            ? `https://fdstaging.com/dealerhop/api/v1/`
            : this.model === `live`
            ? `https://app.dealerhop.com/app/api/v1/`
            : `https://app.dealerhop.com/app/api/v1/`;

    static chat_url =
        this.mode === `local`
            ? `http://localhost:8080/`
            : this.mode === `staging`
            ? `https://fdstaging.com:1575/`
            : this.model === `live`
            ? `https://app.dealerhop.com:1575/`
            : `https://app.dealerhop.com:1575/`;

    static SOCKET_BASE_URL =
        this.mode === `local`
            ? `http://localhost:1576/`
            : this.mode === `staging`
            ? `https://fdstaging.com:1576/`
            : this.model === `live`
            ? `https://app.dealerhop.com:1576/`
            : `https://app.dealerhop.com:1576/`;

    //     //Local
    //      static base_url ="http://localhost/dealerhop/public/api/v1/";
    //      static chat_url ="http://localhost:8080/";
    //      static SOCKET_BASE_URL = "http://localhost:1576/";
    // }
    // if(mode === 'staging'){
    // //Staging
    // static base_url = "https://fdstaging.com/dealerhop/api/v1/";
    // static chat_url = "https://fdstaging.com:1575/";
    // static SOCKET_BASE_URL = "https://fdstaging.com:1576/";
    // }
    // if(mode === 'live'){

    //     //Live
    //     static base_url ="https://app.dealerhop.com/app/api/v1/";
    //     static chat_url ="https://app.dealerhop.com:1575/";
    //     static SOCKET_BASE_URL = "https://app.dealerhop.com:1576/";
    // }

    static LOGIN = this.base_url + "login";
    static REGISTER = this.base_url + "register";
    static FORGOTPASSWORD = this.base_url + "forgotPassword";
    static RESETPASSWORD = this.base_url + "resetPassword";
    static LOGOUT = this.base_url + "logout";
    static MapBoxKey =
        "pk.eyJ1IjoiZGV2ZWxvcGVyYWJpZGFsaSIsImEiOiJjbDF3MG16NzUxNGg0M2txdnNtcDgzbmR2In0.BIJcWX7_Z30IXudESTAbbw";
    static USERTOKEN = "";
    static USERDATA = localStorage.getItem("userData");
    static UPDATECONTACTINFO = this.base_url + "updateContactInfo";
    static MAKEPAYMENT = this.base_url + "makePayment";
    static GETTRANECTIONHISTORY = this.base_url + "getTranectionHistory";
    static GETPROFILE = this.base_url + "getProfile";
    static GETPROFILEINFO = this.base_url + "getProfileInfo";
    static UPDATEPROFILE = this.base_url + "updateProfile";
    static UPDATEEMAIL = this.base_url + "updateEmail";
    static GETCARDS = this.base_url + "getCards";
    static UPDATECARD = this.base_url + "updateCard";
    static DELETECARD = this.base_url + "deleteCard";
    static ADDCARD = this.base_url + "addCard";
    static ADDPAYMENT = this.base_url + "addPayment";
    static GETALLLEAD = this.base_url + "allLeads";
    static GETFULLFILEDLEADS = this.base_url + "getFullfiledLeads";
    static CURRENTCUSTOMERSLEADS = this.base_url + "currentCustomersLeads";
    static GETOPENMARKETLEADS = this.base_url + "getOpenMarketLeads";
    static GETSINGLELEAD = this.base_url + "getSingleLead";
    static SAVELEADCUSTOMER = this.base_url + "saveLeadCustomer";
    static CHNAGEPASSWORD = this.base_url + "changePassword";
    static GETCONTACTINFO = this.base_url + "getContactInfo";
    static UPDATENOTESOLD = this.base_url + "updateNoteSold";
    static SENDMESSAGE = this.base_url + "sendMessage";
    static SENDMAIL = this.base_url + "sendEmail";
    static GETPROVINCE = this.base_url + "getProvince";
    static GETQRCODE = this.base_url + "getQRCode";
    static GOOGLELOGIN = this.base_url + "googleLogin";
    static GOOGLE2fa = this.base_url + "2fa";
    static GOOGLECHECK2FA = this.base_url + "check2fa";
    static GEOCODING = this.base_url + "geocoding";
    static OTPVERIFY = this.base_url + "otpVerify";
    static GETMYUSERLIST = this.base_url + "getMyUserList";
    static ADDUSER = this.base_url + "addUser";
    static UPDATEUSER = this.base_url + "updateUser";
    static GETUSERPROFILE = this.base_url + "getUserProfile";
    static REMOVEUSER = this.base_url + "removeUser";

    static GETREFFREALHISTORY = this.base_url + "getReffrealHistory";
    static SKIPPAYMENT = this.base_url + "skipPayment";
    static GETALLCITY = this.base_url + "getAllCity";
    static UPDATECITIES = this.base_url + "updateCities";
    static HIDELEAD = this.base_url + "hideLead";

    static GETVEHICLELEAD = this.base_url + "getVehicleLead";
    static GETBIDS = this.base_url + "getBids";
    static ADDBID = this.base_url + "addBid";
    static GETWONLEAD = this.base_url + "getWonLead";
    static VEHICLELEADDETAIL = this.base_url + "vehicleLeadDetail";

    static GETALLMACH = this.base_url + "allmaches";
    static GETALLMACHLEAD = this.base_url + "allMachLead";
    static UPDATEALLLEAD = this.base_url + "updateAllLead";
    static REMOVECRMLEAD = this.base_url + "removeCRMLead";

    static GETALLMACHVEHICLELEAD = this.base_url + "allVehicleMachLead";
    static UPDATEALLVEHICLELEAD = this.base_url + "updateAllVehicleLead";

    static CHNAGEPROFILEPIC = this.base_url + "updateProfilePic";
    static ADDTASKTOLEAD = this.base_url + "addToTaskLead";
    static UPDATETASKLEAD = this.base_url + "updateTaskLead";
    static GETTASKTOLEAD = this.base_url + "getTaskToLead";
    static GETDEALERLINK = this.base_url + "getDealerLink";
    static CHNAGEDEALERLOGO = this.base_url + "chnageDealerLogo";
    
    static CANCELSUBSCRIPTION = this.base_url + "subcriptionCancel";
    static STARTSUBSCRIPTION = this.base_url + "subcriptionStart";
    
    static ADDCRMEMAIL = this.base_url + "addCrmEmail";
    static REMOVECRMEMAIL = this.base_url + "removeCrmEmail";
    static GETCRMEMAILS = this.base_url + "getCrmEmails";
    static SENDTESTCRMEMAIL = this.base_url + "sendTestCRMEmail";
    
    static CHAT = this.chat_url + "chat";
    static HEADERDATA = JSON.parse(localStorage.getItem("header_data"));
}

export const routeAccess = (props) => {
    // const userdata1 = localStorage.getItem("userData");
    const userdata = JSON.parse(constant.USERDATA);
    console.log(userdata.data.step);

    let step = 0;

    // console.log(userdata)
    if (userdata) {
        step = userdata.data.step;
    }

    if (step === 2 || step === 1) {
        return <Navigate to="/step/contact_info" />;
    } else if (step === 3) {
        return <Navigate to="/step/account_balance" />;
    } else if (step === 4) {
        return <Navigate to="/account_balance" />;
    } else {
    }

    const login = localStorage.getItem("isLoggedIn");

    if (login) {
        return <Navigate to="/account_balance" />;
    }
};

export const checkAuth = (response) => {
    var code = response?.data?.code;

    if (code === 401) {
        localStorage?.clear();
        window.location.href = "login";
        // return <Navigate  to="/login" />;
    } else if (code === 12 || code === 13) {
        localStorage?.clear();
        window.location.href = "login";
        // return <Navigate  to="/login" />;
    }
};

export class AppImages {
    static nouser = require(`../assets/svg/nouser.svg`);
    static completedtask = require(`../assets/svg/completedtask.svg`);
    static closetask = require(`../assets/svg/closetask.svg`);
    static trashicon = require(`../assets/svg/trashicon.svg`);
    static loadergif = require(`../assets/gif/loader.gif`);
    static removecross = require(`../assets/svg/removecross.svg`);
    static logo_white = require(`../assets/svg/logo_white.svg`).default;
    static logo_black = require(`../assets/svg/logo.svg`).default;
    static greentik = require(`../assets/svg/greentik.svg`).default;
    static p_details = require(`../assets/svg/p_details.svg`).default;
    static delivery_icon = require(`../assets/svg/delivery_icon.svg`).default;
    static address_icon = require(`../assets/svg/address-icon.svg`).default;
    static employ_icon = require(`../assets/svg/employ_icon.svg`).default;
}